import React from "react";
import ToggleOn from "../assets/images/icons/icn_toggle_on.png";
import ToggleOff from "../assets/images/icons/icn_toggle_off.png";
import ExpandIcon from "../assets/images/icons/icn_expand.png";
import Profile from "../assets/images/icons/icn_profile.png";
import ArrowDown from "../assets/images/icons/icn_arrow_down.png";
import MinimizeIcon from "../assets/images/icons/icn_minimize.png";
import ChangePasswordIcon from "../assets/images/icons/icn_change_password.png";
import LogoutIcon from "../assets/images/icons/icn_logout.png";
import { Dropdown } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
const Header = ({ collapsed, setCollapsed }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen().catch((error) => {
        console.error("Error attempting to enable full-screen mode:", error);
      });
    }
  };

  const callLogout = () => {
    return (window.location.href = `${process.env.REACT_APP_LARAVEL_BASE_URL}logout`);
  };
  const items = [
    {
      key: "1",
      label: (
        <div
          //   onClick={handleUserProfile}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={ChangePasswordIcon} alt="edit" style={{ height: "16px" }} />
          <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}change_password`}>
            <span className="mx-2 dropdown-item">Change Password</span>
          </Link>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
        onClick={() => callLogout()}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={LogoutIcon} alt="delete" style={{ height: "18px" }} />
          <span className="mx-2 dropdown-item">
            Logout
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="header-main-div">
        <div
          className="element-space-between"
          style={{ backgroundColor: "#263644" }}
        >
          <div className="trufit-heading-div">
            <p className="trufit-header-heading m-0">TRUFIT</p>
          </div>
          <img
            src={collapsed ? ToggleOff : ToggleOn}
            height={12}
            className="mx-2"
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div
          className="trufit-header-div element-space-between px-4"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <img
            className="mx-2"
            src={isFullscreen ? MinimizeIcon : ExpandIcon}
            height={15}
            onClick={toggleFullscreen}
          />

          <Dropdown
            style={{ cursor: "pointer" }}
            menu={{
              items,
            }}
            overlayClassName="profile-dropdown"
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <div className="d-flex align-items-center mx-3">
              <img src={Profile} height={40} />
              <p className="mx-2 my-0 profile-name">Admin</p>
              <img className="mx-1" src={ArrowDown} height={15} width={15} />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
