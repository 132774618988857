import React from "react";
import { Pagination } from "antd";

const PaginationData = ({total,defaultPageSize, defaultCurrent, onChange}) => {
  return (
    <div className="table-pagination my-3">
      {total !== 0 && (
    <Pagination
      total={total ? total : 100}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      showSizeChanger={true}
      showLessItems={true}
      defaultPageSize={defaultPageSize ? defaultPageSize : 10 }
      defaultCurrent={defaultCurrent ? defaultCurrent : 1}
      onChange={onChange}
    />
    )}
    </div>
  );
};

export default PaginationData;
