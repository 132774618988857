export const registerUserOptions = [
    {
      value: "2",
      label: "All",
    },
    {
      value: "1",
      label: "Free Trail",
    },
    {
      value: "0",
      label: "Subscribed",
    },
  ];

  export const referralUserOptions = [
    {
      value: "",
      label: "All",
    },
    {
      value: "commission_pending",
      label: "Pending",
    },
    {
      value: "commission_received",
      label: "Received",
    },
  ]

  export  const capitalizeFirstLetter = (str) => {
    if (str?.length === 0) {
      return str;
    }
    const firstLetter = str?.charAt(0).toUpperCase();
    const restOfString = str?.slice(1);

    return firstLetter + restOfString;
  };

  export  const UserType = [
    {
      value: "Social Media Influencer",
      label: "Social Media Influencer",
    },
    {
      value: "Company",
      label: "Company",
    },
    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Non-profit Organization",
      label: "Non-profit Organization",
    },
  ];

  export const downloadFile = (url) => {
    const fileUrl = url; // Replace with your file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "Report.csv"; // Provide a name for the downloaded file
    link.click();
  };