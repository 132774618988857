import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, Divider } from "antd";
import { capitalizeFirstLetter } from "../../helper/constant";

const ConfirmationModal = (props) => {
  console.log('props===>>>', props?.currRecord);
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="f-Sans-bold"
            style={{ fontSize: "16px", fontWeight: "800", marginTop: "10px" }}
            id="contained-modal-title-vcenter"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props?.currRecord !== undefined && (
            <>
              <h6 className="bold">Account Details</h6>
              <Row justify="space-between">
                <Col span={10}>
                  <span className="light-grey">Bank Name</span> <br></br>
                  <span className="light-grey">Account Holder</span> <br></br>
                  <span className="light-grey">Account Number</span> <br></br>
                  <span className="light-grey">Amount</span> <br></br>
                </Col>
                <Col span={13}>
                  <Row justify="end" align={"middle"}>
                    <Col style={{ textAlign: "end" }}>
                      <span>
                        {capitalizeFirstLetter(props?.currRecord?.bank_name)}
                      </span>{" "}
                      <br></br>
                      <span>
                        {capitalizeFirstLetter(
                          props?.currRecord?.account_holder
                        )}
                      </span>{" "}
                      <br></br>
                      <span>{props?.currRecord?.account_number}</span>
                        <span>
                        <br></br>
                       {Number(props?.currRecord?.commission_amount)?.toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <div className="t-center text-center">
            <h4 className="payment-approved mt-2">{props?.message}</h4>
            <div>
              <Button
                className="mx-2 csv-btn"
                onClick={() => props?.onSuccess()}
              >
                Yes
              </Button>
              <Button className="mx-2 csv-btn" onClick={() => props?.onHide()}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
