import React, { useState } from "react";
import LoadingOverlay from "../../../components/ui/OverlayLoading";
import Breadcrumb from "../../../components/ui/Breadcrumb";
import affiliateUserImg from "../../../assets/images/icons/icn_affiliate_w.png";
import affiliateUserImgGrey from "../../../assets/images/icons/icn_affiliate.png";
import editIcon from "../../../assets/images/icons/icn_edit.png";
import deleteIcon from "../../../assets/images/icons/icn_delete.png";
import menuIcon from "../../../assets/images/icons/icn_menu.png";
import { Button, Card, Col, DatePicker, Input, Row, Select } from "antd";
import TableData from "../../../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import toastr from "toastr";
import PaginationData from "../../../components/pagination";
import actionBtn from "../../../assets/images/icons/icn_action_btn.png";
import dayjs from "dayjs";
import {
  capitalizeFirstLetter,
  downloadFile,
  referralUserOptions,
} from "../../../helper/constant";
const { RangePicker } = DatePicker;

const ReferralUsers = () => {
  const [state, setState] = useState({ loading: false, referralUsers: [] });
  const [filters, setFilters] = useState({
    search: "",
    date_from: "",
    date_to: "",
    status: "",
  });
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const getReferralById = (page, per_page) => {
    const headers = {
      Token: location?.state?.token.slice(0, -10),
    };
    setState({ ...state, loading: true });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/affiliate/get-referrals?per_page=${per_page}&page=${page}&search=${filters?.search}&referral_id=${location?.state?.id}&date_from=${filters?.date_from}&date_to=${filters?.date_to}&status=${filters?.status}`,
        { headers }
      )
      .then((response) => {
        setState({
          ...state,
          referralUsers: response?.data?.data,
          loading: false,
        });
      })
      .catch((error) => {
        toastr?.error(error.message);
        setState({ ...state, loading: false });
      });
  };
  useEffect(() => {
    getReferralById();
  }, [filters]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setFilters({ ...filters, search: inputValue });
    }, 500);
    return () => clearTimeout(delay);
  }, [inputValue]);

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "user_email_id",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "referral_status",
      key: "status",
      render: (props) => (
        <span
          className={
            props === "commission_received" ? "blue-color" : "yellow-color"
          }
        >
          {props === "commission_pending"
            ? "Pending"
            : props === "commission_received"
            ? "Received"
            : props}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (props) => (
        <span>{props ? dayjs(props).format("D MMM YYYY, h:mm A") : "-"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <img
          src={actionBtn}
          onClick={() =>
            navigate(
              `/trufit/subscription-logs/${location?.state?.token}`,
              { state: { id: record?.user_id, name: record?.user_name } }
            )
          }
          height={30}
        />
      ),
    },
  ];

  const generateCsvHandler = () => {
    const headers = {
      Token: location?.state.token.slice(0, -10),
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/affiliate/get-referrals-csv?referral_id=${location?.state?.id}`,
        { headers }
      )
      .then((response) => {
        const url = process.env.REACT_APP_BUCKET_URL;
        downloadFile(url.concat(response?.data?.data?.csvFileName));
        // setLoading(false);
      })
      .catch((error) => {
        toastr?.error(error.message);
        // setLoading(false);
      });
  };

  const handleSearchChange = (value) => {
    setInputValue(value);
  };

  const onChangeDate = (dates, dateString) => {
    setFilters({
      ...filters,
      date_from: dateString[0],
      date_to: dateString[1],
    });
  };

  const handlePageChange = (page, pageSize) => {
    getReferralById(page, pageSize);
  };

  const handleChange = (value) => {
    setFilters({ ...filters, status: value });
  };

  return (
    <>
      {state?.loading === true && <LoadingOverlay />}
      <div className="mx-4 register-user">
        <Breadcrumb
          name="Referrals"
          pictureWhite={affiliateUserImg}
          pictureGrey={affiliateUserImgGrey}
        />

        <Card className="my-3">
          <Row
            style={{ paddingRight: "10px" }}
            justify={"space-between"}
            align={"middle"}
          >
            <Col>
              <div className="table-heading f-Sans-bold">
                {`Referrals of ${location?.state?.name}`}
              </div>
            </Col>
          </Row>
          <div className="row p-4 mt-3">
            <div className=" element-space-between">
              <RangePicker onChange={onChangeDate} />
              <div className="filter-div d-flex align-items-center filter-div">
                <p className=" mr-2 my-0 ff-nunito">Filter by Status:</p>
                <Select
                  value={capitalizeFirstLetter(
                    filters?.status === "commission_pending"
                      ? "Pending"
                      : filters?.status === "commission_received"
                      ? "Received"
                      : filters?.status
                  )}
                  style={{
                    width: 120,
                  }}
                  onChange={handleChange}
                  options={referralUserOptions}
                />
              </div>

              <Button className="csv-btn" onClick={() => generateCsvHandler()}>
                Generate CSV
              </Button>

              <div className="d-flex align-items-center">
                <p className="mr-2 my-0 ff-nunito">Search:</p>
                <Input
                  className="search-input"
                  autoComplete="false"
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </div>
          </div>
          <TableData
            className="mt-2"
            dataSource={state?.referralUsers?.affiliateReferralUsers}
            columns={columns}
          />
          <PaginationData
            total={state?.referralUsers?.pagination?.count}
            defaultPageSize={state?.referralUsers?.pagination?.per_page}
            defaultCurrent={state?.referralUsers?.pagination?.page}
            onChange={handlePageChange}
          />
        </Card>
      </div>
    </>
  );
};

export default ReferralUsers;
