import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/ui/Breadcrumb";
import {
  Button,
  Card,
  Dropdown,
  Input,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import TableData from "../../components/table";
import toastr from "toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PaginationData from "../../components/pagination";
import tickIcon from "../../assets/images/icons/icn_tick.png";
import crossIcon from "../../assets/images/icons/icn_cross.png";
import RequestApprovedModal from "../../components/modal/RequestApproved";
import withdrawalImg from "../../assets/images/icons/icn_withdraw_w.png";
import withdrawalImgGrey from "../../assets/images/icons/icn_withdraw.png";
import LoadingOverlay from "../../components/ui/OverlayLoading";
import ConfirmationModal from "../../components/modal/Confirmation";
import { capitalizeFirstLetter } from "../../helper/constant";
const { RangePicker } = DatePicker;

const WithdrawalRequests = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(false);
  const [currId, setCurrId] = useState();
  const [currRecord, setCurrRecord] = useState({});
  const [filters, setFilters] = useState({
    date_from: "",
    date_to: "",
    status: "",
    search: "",
  });
  const [inputValue, setInputValue] = useState("");

  const getAffiliateUser = (arr) => {
    let newArr = [];
    for (let i = 0; i < arr?.length; i++) {
      let obj = {
        id: arr[i]?.id,
        status: arr[i]?.status,
        commission_amount: arr[i]?.commission_amount,
        ...arr[i]?.affiliate_user,
        ...arr[i]?.bank,
      };
      newArr.push(obj);
    }
    return newArr;
  };

  const getUserList = (page, per_page) => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/affiliate/get-withdraw-requests?per_page=${per_page}&page=${page}`,
        {
          search: filters?.search,
          status: filters?.status,
        },
        { headers }
      )
      .then((response) => {
        setUserList({
          ...userList,
          transactionsRequests: getAffiliateUser(
            response?.data?.data?.transactionsRequests
          ),
          pagination: response?.data?.data?.pagination,
        });
        setLoading(false);
      })
      .catch((error) => {
        toastr?.error(error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    document.title = "Trufit | Withdrawal";
    localStorage.setItem(
      "Token",
      JSON.stringify({
        Token: location?.pathname.split("/")[3],
      })
    );
    getUserList(1, 10);
  }, [filters]);

  useEffect(() => {
    // This effect will run when the user stops typing for 500 milliseconds
    const delay = setTimeout(() => {
      setFilters({ ...filters, search: inputValue });
    }, 500);

    // Clear the previous timeout on every input change
    return () => clearTimeout(delay);
  }, [inputValue]);

  const handlePageChange = (page, pageSize) => {
    getUserList(page, pageSize);
  };

  const changeRequestHandler = (data, value) => {
    setConfirmation(true);
    setRequestType(value);
    setCurrRecord(data);
    setCurrId(data?.id);
  };

  const onSuccess = () => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/affiliate/update-withdrawal-request`,
        {
          status: requestType,
          transactionId: currId,
        },
        { headers }
      )
      .then((response) => {
        if (response?.status === 200) {
          setConfirmation(false);
          setModalShow(true);
        }
      })
      .catch((error) => {
        toastr?.error(error.message);
      });
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (props) => (
        <span
          className={
            props === "Completed"
              ? "blue-color"
              : props === "Pending"
              ? "yellow-color"
              : "red-color"
          }
        >
          {props}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "commission_amount",
      key: "status",
      render: (_, record) => (
        <span>{Number(record?.commission_amount)?.toFixed(2)}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "bank",
      key: "action",
      render: (_, record) => {
        if (record?.status === "Pending") {
          return (
            <>
              <div style={{ display: "flex", gap: "15px" }}>
                <img
                  src={tickIcon}
                  height={25}
                  onClick={() => changeRequestHandler(record, "Completed")}
                />
                <img
                  src={crossIcon}
                  height={25}
                  onClick={() => changeRequestHandler(record, "Failed")}
                />
              </div>
            </>
          );
        }
      },
    },
  ];

  const handleChange = (value) => {
    if (value === "All") {
      setFilters({ ...filters, status: "" });
    } else {
      setFilters({ ...filters, status: value });
    }
  };

  const handleSearchChange = (value) => {
    setInputValue(value);
  };

  const onHideModal = () => {
    setModalShow(false);
    getUserList(1, 10);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mx-4 register-user">
        <Breadcrumb
          name="Withdrawal Requests"
          pictureWhite={withdrawalImg}
          pictureGrey={withdrawalImgGrey}
        />

        <Card className="my-3">
          <div className="row p-4 mt-3">
            <div className=" d-flex justify-content-between align-items-center">
              <div className="table-heading f-Sans-bold p-0">
                Withdrawal Requests
              </div>
              <div className="d-flex align-items-center filter-div">
                <p className=" mr-2 my-0 ff-nunito">Filter by Status:</p>
                <Select
                  value={capitalizeFirstLetter(
                    filters?.status === "" ? "All" : filters?.status
                  )}
                  style={{
                    width: 120,
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Completed",
                      label: "Completed",
                    },
                    {
                      value: "Failed",
                      label: "Failed",
                    },
                  ]}
                />
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-2 my-0 ff-nunito">Search:</p>
                <Input
                  className="search-input"
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
            </div>
          </div>
          <TableData
            className="mt-2"
            dataSource={userList?.transactionsRequests}
            columns={columns}
          />
          <PaginationData
            total={userList?.pagination?.count}
            defaultPageSize={userList?.pagination?.per_page}
            defaultCurrent={userList?.pagination?.page}
            onChange={handlePageChange}
          />
        </Card>
      </div>

      <RequestApprovedModal
        show={modalShow}
        requestType={requestType}
        onHide={() => onHideModal()}
      />

      <ConfirmationModal
        show={confirmation}
        message={`Are you sure you want to ${
          requestType === "Completed" ? "approve" : "reject"
        } the request?`}
        currRecord={currRecord}
        onSuccess={() => onSuccess()}
        onHide={() => setConfirmation(false)}
      />
    </>
  );
};

export default WithdrawalRequests;
