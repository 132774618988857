import Modal from "react-bootstrap/Modal";
import tickIcon from "../../assets/images/icons/icn_tick.png";
import crossIcon from "../../assets/images/icons/icn_cross.png";

const AlertModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="f-Sans-bold"
            style={{ fontSize: "16px", fontWeight: "800", marginTop: "10px" }}
            id="contained-modal-title-vcenter"
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="t-center text-center">
            <img
              src={props.alertType === "failure" ? crossIcon : tickIcon}
              alt=""
              height={40}
            />
            <div>
              <h4 className="payment-approved mt-2">{props.body}</h4>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertModal;
