import React from "react";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { Card, DatePicker, Input, Select } from "antd";
import TableData from "../../components/table";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PaginationData from "../../components/pagination";
import dayjs from "dayjs";
import toastr from "toastr";
import LoadingOverlay from "../../components/ui/OverlayLoading";
import subscriptionImg from "../../assets/images/icons/icn_subscription-w.png";
import subscriptionImgGrey from "../../assets/images/icons/icn_subscription.png";
import { capitalizeFirstLetter } from "../../helper/constant";
const { RangePicker } = DatePicker;

const SubscriptionLogs = () => {
  const [subscriptionLog, setSubscriptionLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [filters, setFilters] = useState({
    date_from: "",
    date_to: "",
  });

  const getSubscriptionLog = (page, per_page) => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/home/subscription/logs?page=${page}&per_page=${per_page}&id_user=${location?.state?.id}&date_from=${filters?.date_from}&date_to=${filters?.date_to}`,
        { headers }
      )
      .then((response) => {
        setSubscriptionLog(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error?.message);
        toastr?.error(error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    getSubscriptionLog(1, 10);
  }, [filters]);

  const handlePageChange = (page, pageSize) => {
    getSubscriptionLog(page, pageSize);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      render: (props) => <span>{props != null ? props : "-"}</span>,
    },  
    {
      title: "Duration",
      dataIndex: "type",
      key: "type",
      render: (props) => <span>{props != null ? capitalizeFirstLetter(props) : "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (props) => (
        <span className={props === "1" ? "yellow-color" : "blue-color"}>
          {props === "0" ? "Inactive" : props === "1" ? "Active" : props === "2" ? "Cancelled" : props === "3" ? "Paused"  : ""}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "endDate",
      render: (props) => (
        <span>{props ? dayjs(props).format("D MMM YYYY, h:mm A") : "-"}</span>
      ),
    },
  ];

  const onChangeDate = (dates, dateString) => {
    setFilters({
      ...filters,
      date_from: dateString[0],
      date_to: dateString[1],
    });
  };


  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mx-4 register-user">
        <Breadcrumb
          name="Subscription Logs"
          pictureWhite={subscriptionImg}
          pictureGrey={subscriptionImgGrey}
        />

        <Card className="mt-3">
          <div className="row p-4 mt-3">
            <div className="col-lg-3 d-flex align-items-center show-entries"></div>
            <div className="d-flex justify-content-between">
              <div className="table-heading f-Sans-bold p-0">
                {`${capitalizeFirstLetter(
                  location?.state?.name
                )}'s Subscription Logs`}
              </div>
              <div className="d-flex align-items-center filter-div">
              <RangePicker onChange={onChangeDate} />
              </div>
            </div>
          </div>
          <TableData
            className="mt-2"
            dataSource={subscriptionLog?.logs}
            columns={columns}
            // actionOption={actionOption}
            // actionIcon={<img src={actionBtn} height={30}/>}
          />
          <PaginationData
            total={subscriptionLog?.pagination?.count}
            defaultPageSize={subscriptionLog?.pagination?.per_page}
            defaultCurrent={subscriptionLog?.pagination?.page}
            onChange={handlePageChange}
          />
        </Card>
      </div>
    </>
  );
};

export default SubscriptionLogs;
