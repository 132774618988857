import React, { useState } from "react";
import { Dropdown, Table } from "antd";
import { useNavigate } from "react-router-dom";
const { Column } = Table;
const TableData = (props) => {
  const [data, setData] = useState();
  let { dataSource, columns, actionOption, className, actionIcon, actionChange, sorted } = props;
  const navigate = useNavigate();
  const items =
    actionOption &&
    actionOption.map((x, i) => {
      return {
        label: (
          <div
          // onClick={() => handleViewMember(data)}
          >
            {/* <img alt="" src={Edit} className="me-2 edit-action" /> */}
            {x.name}
          </div>
        ),
        key: i,
      };
    });

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  return (
    <div className={className}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        // showSizeChanger={true}
        rowClassName={getRowClassName}
      >
        {/* {columns.map((x, i) => {
          return <Column title={x.title} dataIndex={x.dataIndex} key={x.key} render={x.render} sorter={true} />;
        })}

        {actionOption ? (
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Dropdown
                onClick={() => setData(record)}
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <span
                  onClick={(e) => e.stopPropagation()}
                  className="action-menu"
                >
                  {actionIcon}
                </span>
              </Dropdown>
            )}
          />
        ) : (
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <span
                onClick={(e) => actionChange()}
                className="action-menu"
              >
                {actionIcon}
              </span>
            )}
          />
        )} */}
      </Table>
    </div>
  );
};

export default TableData;
