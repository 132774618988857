import React, { useEffect, useState } from "react";
import { Col, Row, Input, Button, Select, Card } from "antd";
import editIcon from "../../assets/images/icons/icn_edit.png";
import deleteIcon from "../../assets/images/icons/icn_delete.png";
import menuIcon from "../../assets/images/icons/icn_menu.png";
import referralIcon from "../../assets/images/icons/icn_action_referral.png";
import "./affiliate-user-listing.css";
import axios from "axios";
import toastr from "toastr";
import PaginationData from "../../components/pagination";
import { useLocation, useNavigate } from "react-router-dom";
import TableData from "../../components/table";
import RequestApprovedModal from "../../components/modal/RequestApproved";
import AddUserModal from "../../components/modal/AddAffiliateUser";
import Breadcrumb from "../../components/ui/Breadcrumb";
import affiliateUserImg from "../../assets/images/icons/icn_affiliate_w.png";
import affiliateUserImgGrey from "../../assets/images/icons/icn_affiliate.png";
import LoadingOverlay from "../../components/ui/OverlayLoading";
import ConfirmationModal from "../../components/modal/Confirmation";
import AlertModal from "../../components/modal/AlertModal";
const AffiliateUserListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [cofirmation, setConfirmation] = useState(false);
  const [userList, setUserList] = useState({});
  const [filters, setFilters] = useState({ search: "", crudCase: "" });
  const [inputValue, setInputValue] = useState("");
  const [currUser, setCurrUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [currId, setCurrId] = useState();
  const [userData, setUserData] = useState({
    type: "",
    userName: "",
    email: "",
  });

  const getUserList = (page, per_page) => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/affiliate?per_page=${per_page}&page=${page}&search=${filters?.search}`,
        { headers }
      )
      .then((response) => {
        setUserList(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        toastr?.error(error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    document.title = "Trufit | Affiliate User";
    localStorage.setItem(
      "Token",
      JSON.stringify({
        Token: location?.pathname.split("/")[3],
      })
    );
    getUserList(1, 10);
  }, [filters]);

  const handlePageChange = (page, pageSize) => {
    getUserList(page, pageSize);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setFilters({ ...filters, search: inputValue });
    }, 500);
    return () => clearTimeout(delay);
  }, [inputValue]);

  const editHandler = (value) => {
    setUserData({
      ...userData,
      type: value?.type,
      userName: value?.user_name,
      email: value?.email,
    });
    setCurrUser(value);
    setFilters({ ...filters, crudCase: "edit" });
    setModalShow(true);
  };

  const deleteHandler = (value) => {
    setConfirmation(true);
    setCurrId(value?.id);
  };

  const onSuccess = () => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/affiliate/delete`,
        {
          affiliateUserId: currId,
        },
        { headers }
      )
      .then((response) => {
        if (response?.status === 200) {
          setConfirmation(false);
          setRequestModal(true);
          getUserList(1, 10);
        }
      })
      .catch((error) => {
        toastr?.error(error.message);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "age",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "status",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (_, record) => (
        <span>{Number(record?.balance)?.toFixed(2)}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "15px" }}>
          {/* <img src={menuIcon} height={25} /> */}
          <img
            src={referralIcon}
            height={25}
            onClick={() =>
              navigate(`/trufit/referral-user`, {
                state: {
                  id: record?.id,
                  name: record?.user_name,
                  token: location?.pathname.split("/")[3],
                },
              })
            }
          />
          <img src={editIcon} height={25} onClick={() => editHandler(record)} />
          <img
            src={deleteIcon}
            height={25}
            onClick={() => deleteHandler(record)}
          />
        </div>
      ),
    },
  ];

  const onHideModal = () => {
    setModalShow(false);
    getUserList(1, 10);
  };

  const addNewAffiliate = () => {
    setCurrUser({});
    setFilters({ ...filters, crudCase: "create" });
    setModalShow(true);
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mx-4 register-user">
        <Breadcrumb
          name="Affiliate Users"
          pictureWhite={affiliateUserImg}
          pictureGrey={affiliateUserImgGrey}
        />

        <Card className="my-3">
          <Row
            style={{ paddingRight: "10px" }}
            justify={"space-between"}
            align={"middle"}
          >
            <Col>
              <div className="table-heading f-Sans-bold">
                Affiliate Users List
              </div>
            </Col>
            <Col style={{ display: "flex", gap: "10px", paddingTop: "15px" }}>
              <Button
                variant="primary"
                onClick={() => addNewAffiliate()}
                style={{
                  width: "100%",
                  borderRadius: "21px",
                  background: "#0d99ff",
                  color: "white",
                }}
              >
                {" "}
                + Add New{" "}
              </Button>{" "}
            </Col>
          </Row>
          <div className="row p-4 mt-3">
            <div className="col-lg-3 d-flex align-items-center show-entries">
            <p className="mr-2 my-0 ff-nunito bold">Cumulative Amount : {Number(userList?.balance)?.toFixed(2)}</p>
            </div>
            <div className="col-lg-9 d-flex justify-content-end">
              <div className="d-flex align-items-center">
                <p className="mr-2 my-0 ff-nunito">Search:</p>
                <Input
                  className="search-input"
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
            </div>
          </div>
          <TableData
            className="mt-2"
            dataSource={userList?.affiliateUsers}
            columns={columns}
            // actionOption={actionOption}
            // actionIcon={<img src={actionBtn} height={30}/>}
          />
          <PaginationData
            total={userList?.pagination?.count}
            defaultPageSize={userList?.pagination?.per_page}
            defaultCurrent={userList?.pagination?.page}
            onChange={handlePageChange}
          />
        </Card>
      </div>

      <AddUserModal
        show={modalShow}
        userData={userData}
        setUserData={setUserData}
        case={filters?.crudCase}
        user={currUser}
        token={location?.pathname.split("/")[3].slice(0, -10)}
        onHide={() => onHideModal()}
      />
      <AlertModal
        show={requestModal}
        title={"Success"}
        alertType={"success"}
        body={"Affiliate deleted successfully."}
        onHide={() => setRequestModal(false)}
      />
      <ConfirmationModal
        show={cofirmation}
        message="Are you sure you want to delete this user?"
        onSuccess={() => onSuccess()}
        onHide={() => setConfirmation(false)}
      />
    </>
  );
};

export default AffiliateUserListing;
