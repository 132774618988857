import "./App.css";
import "./assets/styles/main.scss";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Layout } from "antd";
import AppLayout from "./pages/Layout/Layout";
import RegisterUser from "./pages/Users/RegisterUsers";
import SubscriptionLogs from "./pages/Subscription/SubscriptionLogs";
import AffiliateUserListing from "./pages/Afiliate-Users/affiliate-user-listing";
import WithdrawalRequests from "./pages/Withdrawal-Requests/WithdrawalRequests";
import { useEffect } from "react";
import ReferralUsers from "./pages/Afiliate-Users/Components/ReferralUsers";

function App() {
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/") {
      return (window.location.href = `${process.env.REACT_APP_LARAVEL_BASE_URL}admin`);
    }
  }, []);
  return (
    <Routes>
      <Route path="trufit" element={<AppLayout />}>
        <Route path="register-user/:id" element={<RegisterUser />} />
        <Route path="subscription-logs/:id" element={<SubscriptionLogs />} />
        <Route path="affiliate-user/:id" element={<AffiliateUserListing />} />
        <Route path="withdrawal/:id" element={<WithdrawalRequests />} />
        <Route path="referral-user" element={<ReferralUsers />} />
      </Route>
    </Routes>
  );
}

export default App;
