import React from 'react'
import BreadcrumbIcon from '../../assets/images/icons/icn_breadcrumb.png'
import HomeIcon from '../../assets/images/icons/icnn_home.png'
const Breadcrumb = ({name, pictureWhite, pictureGrey}) => {
  return (
    <>
    <div className='element-space-between mt-4 mx-2'>
<div className='d-flex align-items-center'>
  <div className='element-center' style={{height:'35px', width:'35px', backgroundColor:'#469CFC', borderRadius:'5px'}}>
<img src={pictureWhite}  />
  </div>
<p className='mx-2 my-0 breadcrumb-heading'>{name}</p>
</div>
<div className='d-flex align-items-center'>
<img  className="m-2" src={pictureGrey} height={14} width={14}/>
<p className=' breadcrumb-track m-0'>/{"   "}{name}</p>
</div>
    </div>
    </>
  )
}

export default Breadcrumb