import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../../components/header";
import SiderMenu from "../../components/sider/SiderMenu";
const { Content, Sider } = Layout;
const AppLayout = ({ is_feed }) => {
  const [collapsed, setCollapsed] = useState(false);


  return (
    <>
      <Layout style={{height:"100%"}} >
        <Header 
        collapsed={collapsed} setCollapsed={setCollapsed}
         />
        <Layout>
          <Sider
            style={{
              paddingInline: "0px",
              width:'250px !important',
              display: collapsed ?  "none"  : 'block' }}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <SiderMenu />
            {/* <p>dfddffdfddf</p> */}
          </Sider>
          <Layout>
            
            <Content
              style={{
                margin: 0,
                backgroundColor: "#F2F7FB",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
