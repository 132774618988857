import React, { useEffect, useState } from "react";
import { Menu, theme } from "antd";
import dashboardIcon from "../../assets/images/icons/icn_dashboard_w.png";
import userIcon from "../../assets/images/icons/icn_user.png";
import exercisesIcon from "../../assets/images/icons/icn_exercise.png";
import achievementsIcon from "../../assets/images/icons/icn_achievements.png";
import disabilitiesIcon from "../../assets/images/icons/icn_disabilities.png";
import focusIcon from "../../assets/images/icons/icn_focuses.png";
import equipmentsIcon from "../../assets/images/icons/icn_equipments.png";
import groupsIcon from "../../assets/images/icons/icn_groups.png";
import workoutIcon from "../../assets/images/icons/icn_workouts.png";
import paymentIcon from "../../assets/images/icons/icn_payment.png";
import registerUserIcon from "../../assets/images/icons/icn_user.png";
import affiliateUserIcon from "../../assets/images/icons/icn_affiliate.png";
import withdrawalIcon from "../../assets/images/icons/icn_withdraw.png";
import settingIcon from "../../assets/images/icons/icn_focuses.png";
import { Link, useLocation } from "react-router-dom";
const SiderMenu = () => {
  const [key, setKey] = useState("");
  const location = useLocation();
  const Auth = JSON.parse(localStorage.getItem("Token"))?.Token

  useEffect(() => {
    if (location?.pathname.split("/")[2] === `register-user`) {
      setKey("11");
    } else if (location?.pathname.split("/")[2] === `affiliate-user`) {
      setKey("12");
    } else if (
      location?.pathname.split("/")[2] === `withdrawal`
    ) {
      setKey("13");
    }
  }, [location]);

  const onClick = (e) => {
    setKey(e.key);
  };
  return (
    <>
      <div className="demo-logo-vertical" />
      <Menu
        onClick={onClick}
        className="mt-1"
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        selectedKeys={key}
        items={[
          {
            key: "1",
            icon: <img src={dashboardIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}welcome`}>
                <span>Dashboard</span>
              </Link>
            ),
          },
          {
            key: "2",
            icon: <img src={userIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}User`}>
                <span>Users</span>
              </Link>
            ),
          },
          {
            key: "3",
            icon: <img src={exercisesIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Exercises`}>
                <span>Exercises</span>
              </Link>
            ),
          },
          {
            key: "4",
            icon: <img src={achievementsIcon} />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Achievements`}>
                <span>Achievements</span>
              </Link>
            ),
          },
          {
            key: "5",
            icon: <img src={disabilitiesIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Disabilities`}>
                <span>Disabilities</span>
              </Link>
            ),
          },
          {
            key: "6",
            icon: <img src={focusIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Focus`}>
                <span>Focuses</span>
              </Link>
            ),
          },
          {
            key: "7",
            icon: <img src={equipmentsIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Equipment`}>
                <span>Equipments</span>
              </Link>
            ),
          },
          {
            key: "8",
            icon: <img src={groupsIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Group`}>
                <span>Groups</span>
              </Link>
            ),
          },
          {
            key: "9",
            icon: <img src={workoutIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Live_Workout`}>
                <span>Workouts</span>
              </Link>
            ),
          },
          {
            key: "10",
            icon: <img src={paymentIcon} />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}Payment`}>
                <span>Payment Details</span>
              </Link>
            ),
          },
          {
            key: "11",
            icon: <img src={registerUserIcon}  />,
            label: (
              <Link to={`register-user/${Auth}`}>
                <span>Registered Users</span>
              </Link>
            ),
          },
          {
            key: "12",
            icon: <img src={affiliateUserIcon}  />,
            label: (
              <Link  to={`affiliate-user/${Auth}`}>
                <span>Affiliate Users</span>
              </Link>
            ),
          },
          {
            key: "13",
            icon: <img src={withdrawalIcon} />,
            label: (
              <Link to={`withdrawal/${Auth}`}>
                <span>Withdrawal Requests</span>
              </Link>
            ),
          },
          {
            key: "14",
            icon: <img src={settingIcon}  />,
            label: (
              <Link to={`${process.env.REACT_APP_LARAVEL_BASE_URL}/Settings`}>
                <span>Settings</span>
              </Link>
            ),
          },
        ]}
      />
    </>
  );
};

export default SiderMenu;
