import { Col, Row, Button, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import toastr from "toastr";
import { UserType } from "../../helper/constant";
const AddUserModal = (props) => {
  const addUserhandler = () => {
    if (
      props?.userData?.type !== "" &&
      props?.userData?.userName !== "" &&
      props?.userData?.email !== ""
    ) {
      const headers = {
        Token: props?.token,
      };

      axios
        .post(
          props?.case === "create"
            ? `${process.env.REACT_APP_API_URL}/affiliate/create`
            : `${process.env.REACT_APP_API_URL}/affiliate/update`,
          props?.case === "create"
            ? props?.userData
            : { ...props?.userData, affiliateUserId: props?.user?.id },
          { headers }
        )
        .then((response) => {
          props?.onHide();
        })
        .catch((error) => {
          toastr?.error(error.response.data.message);
        });
    }
  };

  const handleChange = (val) => {
    props?.setUserData({ ...props?.userData, type: val });
  };

 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="f-Sans-bold"
          style={{ fontSize: "16px", fontWeight: "800" }}
          id="contained-modal-title-vcenter"
        >
          {`${props?.case === "edit" ? "Edit" : "Add"} Affiliate User`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row justify="space-around" align={"middle"} className="mt-30">
          <Col span={5}>
            <strong className="f-Sans-bold">User Type</strong>
          </Col>
          <Col span={17}>
            <span>
              <Select
                placeholder="Please Select the Type"
                defaultValue={props?.user?.type}
                style={{
                  width: "100%",
                }}
                onChange={handleChange}
                options={UserType}
              />
            </span>
          </Col>
        </Row>

        <Row justify="space-around" align={"middle"} className="mt-30">
          <Col span={5}>
            <strong className="f-Sans-bold">Name</strong>
          </Col>
          <Col span={17}>
            <span>
              <input
                defaultValue={props?.user?.user_name}
                type="text"
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) =>
                  props?.setUserData({
                    ...props?.userData,
                    userName: e.target.value,
                  })
                }
              />
            </span>
          </Col>
        </Row>

        <Row justify="space-around" align={"middle"} className="mt-30">
          <Col span={5}>
            <strong className="f-Sans-bold">Email</strong>
          </Col>
          <Col span={17}>
            <span className="f-Sans-bold">
              <input
                value={props?.user?.email}
                type="text"
                className="form-control"
                placeholder="Enter Email"
                onChange={(e) =>
                  props?.setUserData({
                    ...props?.userData,
                    email: e.target.value,
                  })
                }
              />
            </span>
          </Col>
        </Row>

        <div className="d-flex justify-content-center" style={{ marginTop: "30px", marginLeft: "10px" }}>
          <Button
            variant="primary"
            className="f-Sans-bold csv-btn"
            style={{ width: "35%", borderRadius: "21px" }}
            onClick={() => addUserhandler()}
          >
            {props?.case === "edit" ? "Update User" : "Add User"}
          </Button>{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
