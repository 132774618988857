import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/ui/Breadcrumb";
import { Button, Card, Dropdown, Input, Select, Space, message } from "antd";
import TableData from "../../components/table";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import actionBtn from "../../assets/images/icons/icn_action_btn.png";
import { DatePicker } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PaginationData from "../../components/pagination";
import regUserImg from "../../assets/images/icons/icn_user_w.png";
import regUserImgGrey from "../../assets/images/icons/icn_user.png";
import dayjs from "dayjs";
import toastr from "toastr";
import LoadingOverlay from "../../components/ui/OverlayLoading";
import { capitalizeFirstLetter, downloadFile, registerUserOptions } from "../../helper/constant";
const { RangePicker } = DatePicker;
const RegisterUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userList, setUserList] = useState({});
  const [filters, setFilters] = useState({
    date_from: "",
    date_to: "",
    status: "all",
    search: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const getUserList = (page, per_page) => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };
    setLoading(true);
    axios
      .get(
        filters?.search === 'all' ?
        `${process.env.REACT_APP_API_URL}/home/athlete-list?per_page=${per_page}&page=${page}&date_from=${filters?.date_from}&date_to=${filters?.date_to}&status=${filters?.status}`
        :
         `${process.env.REACT_APP_API_URL}/home/athlete-list?per_page=${per_page}&page=${page}&date_from=${filters?.date_from}&date_to=${filters?.date_to}&status=${filters?.status}&search=${filters?.search}`,
        { headers }
      )
      .then((response) => {
        setUserList(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        toastr?.error(error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    document.title = "Trufit | Register User";
    localStorage.setItem(
      "Token",
      JSON.stringify({
        Token: location?.pathname.split("/")[3],
      })
    );
    getUserList(1, 10);
  }, [filters]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setFilters({ ...filters, search: inputValue });
    }, 500);
    return () => clearTimeout(delay);
  }, [inputValue]);

  const handlePageChange = (page, pageSize) => {
    getUserList(page, pageSize);
  };


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (props) => (
        <span
          className={props === "Subscribed" ? "blue-color" : "yellow-color"}
        >
          {props}
        </span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_datetime",
      key: "startDate",
      render: (props) => (
        <span>{props ? dayjs(props).format("D MMM YYYY, h:mm A") : "-"}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "expiry_datetime",
      key: "endDate",
      render: (props) => (
        <span>{props ? dayjs(props).format("D MMM YYYY, h:mm A") : "-"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <img
          src={actionBtn}
          onClick={() =>
            navigate(
              `/trufit/subscription-logs/${location?.pathname.split("/")[3]}`,
              { state: { id: record?.user_id, name: record?.user_name  } }
            )
          }
          height={30}
        />
      ),
    },
  ];

  const handleChange = (value) => {
    if (value === "2") {
      setFilters({ ...filters, status: "all" });
    } else {
      setFilters({ ...filters, status: value });
    }
  };

  const handleSearchChange = (value) => {
    setInputValue(value);
  };

  const onChangeDate = (dates, dateString) => {
    setFilters({
      ...filters,
      date_from: dateString[0],
      date_to: dateString[1],
    });
  };


  const generateCsvHandler = () => {
    const headers = {
      Token: location?.pathname.split("/")[3].slice(0, -10),
    };
    // setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/home/generate-coach-care-taker-list-csv`,
        { userType: "athlete" },
        { headers }
      )
      .then((response) => {
        const url = process.env.REACT_APP_BUCKET_URL;
        downloadFile(url.concat(response?.data?.data?.csvFileName));
        // setLoading(false);
      })
      .catch((error) => {
        toastr?.error(error.message);
        // setLoading(false);
      });
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mx-4 register-user">
        <Breadcrumb
          name="Users"
          pictureWhite={regUserImg}
          pictureGrey={regUserImgGrey}
        />

        <Card className="my-3">
          <div className="row p-4 mt-3">
            <div className=" element-space-between">
              <RangePicker onChange={onChangeDate} />
              <div className="filter-div d-flex align-items-center filter-div">
                <p className=" mr-2 my-0 ff-nunito">Filter by Status:</p>
                <Select
                value={capitalizeFirstLetter(filters?.status)}
                  style={{
                    width: 120,
                  }}
                  onChange={handleChange}
                  options={registerUserOptions}
                />
              </div>

              <Button className="csv-btn" onClick={() => generateCsvHandler()}>
                Generate CSV
              </Button>

              <div className="d-flex align-items-center">
                <p className="mr-2 my-0 ff-nunito">Search:</p>
                <Input
                  className="search-input"
                  autoComplete="false"
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </div>
          </div>
          <TableData
            className="mt-2"
            dataSource={userList?.list}
            columns={columns}
          />
          <PaginationData
            total={userList?.pagination?.count}
            defaultPageSize={userList?.pagination?.per_page}
            defaultCurrent={userList?.pagination?.page}
            onChange={handlePageChange}
          />
        </Card>
      </div>
    </>
  );
};

export default RegisterUser;
